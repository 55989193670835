const linkConfig = {
  web: "https://www.bito666.com",
  online:
    "https://ytglcs.s5.udesk.cn/im_client/?web_plugin_id=1761?from=%E5%9C%A8%E7%BA%BF%E6%94%AF%E6%8C%81&group=0#/",
  login: "https://fo.tradeglzx.com/Frontoffice",
  andorid: "https://downloads.hsyxxhyy.com/source/material/fttx.apk",
  ios: "https://downloads.hsyxxhyy.com/source/material/download_fttx.plist",
  download: "https://39.108.127.136:6010/source/material/tuijian.apk",
};

const sourceUrl = "_s_u_l_";

export { linkConfig, sourceUrl };
