import React, { useCallback } from "react";
// import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import styles from "./styles.module.scss";
import { linkConfig } from "../config";
export default () => {
  const goAppHome = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (window.isNative) {
      window.isNative.ResultByJs && window.isNative.ResultByJs(5);
      return;
    }
    if (window.webkit && window.webkit.messageHandlers.ResultByJs) {
      window.webkit.messageHandlers.ResultByJs.postMessage({ body: 5 });
      return;
    }
    if (window.iOSNative) {
      window.iOSNative(5);
      return;
    }
    window.location.href = linkConfig.web;
  }, []);
  return (
    <div className="fixed-screen">
      {/* <Navigation /> */}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.audit}></div>
          <h2>资料正在审核</h2>
          <p>
            预计1-3个工作日会有结果，如有疑问，请联系
            <a href={linkConfig.online} target="_blank">
              在线客服
            </a>
          </p>
          <p>
            <span onClick={goAppHome} className={styles.btn}>
              返回
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
