import axios from "axios";

/**
 * 请求拦截
 */
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
axios.interceptors.response.use(
  function (response) {
    if (response.status == 200) {
      if (Array.isArray(response.data)) {
        return {
          success: true,
          list: response.data,
        };
      }
      return {
        success: true,
        ...response.data,
      };
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * 请求封装
 * @param  {String} method 请求方法，如get，post，put....
 * @param  {String} url 请求地址
 * @param  {Object} body 请求参数
 */
export default ({ method = "get", url, body = {}, timeout = 30000 }) => {
  const { headers = {}, withCredentials = false, ...postBody } = body;
  const params = {
    method,
    url,
    timeout,
    withCredentials,
    headers: {
      ...headers,
    },
  };
  if (method.toLowerCase() == "post") {
    //   post请求
    params.data = {
      ...postBody,
    };
  } else if (method.toLowerCase() == "get") {
    //   get请求
    params.params = {
      ...postBody,
    };
  }
  return axios(params)
    .then((res) => res)
    .catch((err) => err);
};
