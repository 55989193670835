import React from "react";
import styles from "./styles.module.scss";

export default ({ bannerType }) => {
  const type = bannerType.toString();
  const search = decodeURIComponent(window.location.search)
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  return (
    <div className={styles.banner_wrap}>
      {type === "1" && (
        <div className={`${styles.banner1}`}>
          {search.phone ? (
            <span className={`${styles.searchPhone}`}>{search.phone}</span>
          ) : (
            <span className={`${styles.searchPhone}`}></span>
          )}
        </div>
      )}
      {type == "2" && <div className={`${styles.banner2}`}></div>}
      {type == "3" && <div className={`${styles.banner3}`}></div>}
    </div>
  );
};
