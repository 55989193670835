import React from "react";
import Layout from "../components/Layout";
import GetProcess from "../components/GetProcess";
import styles from "./styles.module.scss";
import { linkConfig } from "../config";

export default () => {
  // 领取流程參數
  const processArr = [
    {
      number: 1,
      type: "success",
    },
    {
      number: 2,
      type: "success",
    },
    {
      number: 3,
      type: "success",
    },
    {
      number: 4,
      type: "active",
    },
  ];

  const goTransaction = () => {
    window.open(linkConfig.download);
  };

  return (
    <Layout
      className="flex-container"
      skip={false}
      showBanner={true}
      showDownload={true}
      bannerType={3}
    >
      <div className={`${styles.wrapper}`}>
        <button onClick={goTransaction} className="block-show btn btn-register isMobile">
          下载APP查看
        </button>
        <div className={styles.desktop_process_wrap}>
          <div className={styles.download_desktop}>
            <h3>下载APP</h3>
            <i></i>
          </div>
        </div>
      </div>
      <GetProcess processArr={processArr} />
    </Layout>
  );
};
