import React from "react";
import styles from "./styles.module.scss";

// processArr外部傳入判斷UI樣式
export default ({ processArr }) => {
  return (
    <div className={styles.getProcess_wrapper}>
      <h3>领取流程</h3>
      <section className={styles.getProcess_content}>
        <ul className={styles.num_process}>
          <div className="border"></div>
          {processArr ? (
            processArr.map((item) => {
              return item.type === "success" ? (
                <li key={item.number} className="num_success"></li>
              ) : (
                <li
                  key={item.number}
                  className={`${item.type === "active" ? "num_active" : ""}`}
                >
                  {item.number}
                </li>
              );
            })
          ) : (
            <>
              <li>1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
            </>
          )}
        </ul>
        {processArr ? (
          <ul className={styles.content}>
            <li
              className={
                processArr[0].type === "success" ? styles.bg_active : ""
              }
            >
              <i className={styles.icon_account}></i>
              <div>
                <h4>30秒开户</h4>
                <p>仅需1个手机号，极速开户</p>
              </div>
            </li>
            <li
              className={
                processArr[1].type === "success" ? styles.bg_active : ""
              }
            >
              <i className={styles.icon_info}></i>
              <div>
                <h4>完善资料</h4>
                <p>个人资料认证，安全保障</p>
              </div>
            </li>
            <li
              className={
                processArr[2].type === "success" ? styles.bg_active : ""
              }
            >
              <i className={styles.icon_envelope}></i>
              <div>
                <h4>红包到账</h4>
                <p>50元红包发放至注册账户</p>
              </div>
            </li>
            <li
              className={
                processArr[3].type === "success" ? styles.bg_active : ""
              }
            >
              <i className={styles.icon_download}></i>
              <div>
                <h4>下载APP</h4>
                <p>手机号登录即可使用红包</p>
              </div>
            </li>
          </ul>
        ) : (
          ""
        )}
      </section>
    </div>
  );
};
