import React, { useState, useCallback } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import AppDownload from "../AppDownload";
import BannerWrap from "../BannerWrap";
import { linkConfig } from "../../config";
import styles from "./styles.module.scss";
import Utils from '../../utils';
import { parse } from "qs";
import { useLocation } from 'react-router-dom';

const downloadNow = Utils.downloadNow;


export default ({
  children,
  className = "",
  step=1,
  title = "开户可领取50元红包,确定放弃吗？",
  cancel = "确认放弃",
  confirm = "继续领取",
  again_title = "下载APP领取$6000赠金！",
  again_cancel = "暂不领取",
  again_confirm = "立即下载",
  redirect = "",
  skip = false,
  showBanner = false,
  showDownload = false,
  bannerType = 1,
}) => {

  const [state, setState] = useState({
    visible: false,
    again_visible: false,
  });

  const location = useLocation();
  const params = parse(location.search.slice(1));
  
  const isApp = () => {
    if (params.from instanceof Array) {
      return params.from.includes('app')
    } else {
      return params.from === 'app'
    }
  }
  const clientWidth = document.body.clientWidth || 1080;
  const isPc = clientWidth > 1080;
  
  const isApp_key = isApp() || isPc;


  const submit = useCallback(() => {
    setState((t) => ({ ...t, visible: !t.visible }));
  }, []);

  const handleCancel = useCallback(() => {
    if (isApp_key) {
      step === 1 ? goHomeApp() : goCenter()
    } else {
      setState(t => ({ visible: false, again_visible: true }))
    }
  })

  const goCenter = useCallback(() => {
    const sessionStorage = window.sessionStorage;
    const phone = sessionStorage.getItem("__r__");
    const pwd = sessionStorage.getItem("__p__");
    const signature = phone + "#" + pwd + "#1#0";
    if (phone && pwd) {
      if (window.isNative && window.isNative.willLoginWith) {
        window.isNative.willLoginWith &&
          window.isNative.willLoginWith(signature);
        return;
      }
      if (window.webkit && window.webkit.messageHandlers.willLoginWith) {
        window.webkit.messageHandlers.willLoginWith.postMessage({
          body: signature,
        });
        return;
      }
      if (window.willLoginWith) {
        window.willLoginWith(signature);
        return;
      }
    }

    if (window.isNative) {
      window.isNative.ResultByJs && window.isNative.ResultByJs(1);
      return;
    }
    if (window.webkit && window.webkit.messageHandlers.ResultByJs) {
      window.webkit.messageHandlers.ResultByJs.postMessage({ body: 1 });
      return;
    }
    if (window.iOSNative) {
      window.iOSNative(1);
      return;
    }
    window.location.href = redirect || linkConfig.web;
  }, [redirect]);

  const goHomeApp = useCallback(() => {
    const sessionStorage = window.sessionStorage;
    const phone = sessionStorage.getItem("__r__");
    const pwd = sessionStorage.getItem("__p__");
    const signature = phone + "#" + pwd + "#1#0";
    if (phone && pwd) {
      if (window.isNative && window.isNative.willLoginWith) {
        window.isNative.willLoginWith &&
          window.isNative.willLoginWith(signature);
        return;
      }
      if (window.webkit && window.webkit.messageHandlers.willLoginWith) {
        window.webkit.messageHandlers.willLoginWith.postMessage({
          body: signature,
        });
        return;
      }
      if (window.willLoginWith) {
        window.willLoginWith(signature);
        return;
      }
    }

    if (window.isNative) {
      window.isNative.ResultByJs && window.isNative.ResultByJs(5);
      return;
    }
    if (window.webkit && window.webkit.messageHandlers.ResultByJs) {
      window.webkit.messageHandlers.ResultByJs.postMessage({ body: 5 });
      return;
    }
    if (window.iOSNative) {
      window.iOSNative(5);
      return;
    }
    window.location.href = redirect || linkConfig.web;
  }, [redirect]);

  return (
    <main>
      {
        showDownload && <AppDownload />
      }
      <Navigation submit={submit} />
      {
        showBanner && <BannerWrap bannerType={bannerType}/>
      }
      <div className={`${styles.main} ${className}`}>
        {children}
        {skip && <div className="skip-btn" onClick={submit}> 跳过 </div>}
      </div>
      {state.visible && (
        <div className={styles.modal}>
          <div>
            <h3>{title}</h3>
            <div>
              <span onClick={submit}>
                {confirm}
              </span>
              <span onClick={handleCancel}>
                {cancel}
              </span>
            </div>
          </div>
        </div>
      )}
      {state.again_visible && (
        <div className={styles.modal}>
          <div className="modal-again">
            <h3>{again_title}</h3>
            <div>
              <span onClick={downloadNow}>
                {again_confirm}
              </span>
              <span onClick={ step === 1 ? goHomeApp : goCenter }>
                {again_cancel}
              </span>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </main>
  );
};
