import React, { useState, useCallback, useEffect } from "react";
import styles from "./styles.module.scss";
import { linkConfig } from "../../config";
export default () => {
  return (
    <div className={styles.bar}>
      <i></i>
      <p>下载APP 交易更轻松</p>
      <a href={linkConfig.download} download target="_blank">
        下载
      </a>
    </div>
  )
};
