/**
常用正则表达式
 */
export default {
  validate: {
    // 手机号
    phone: /^(((1[03456789][0-9]{1})|(15[0-9]{1}))+\d{8})$/,
    //  用户密码
    password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/,
    //电子邮箱
    email: /^(?!\s+)[A-Za-z0-9_\-\.]+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    // 手机验证码
    captcha: /^\d{4}$/,
    // 中文名
    chineseName: /^[\u4e00-\u9fa5]{1,15}$/,
    // 证件类型
    idDocumentNumber: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
  },
};

/**
 * 设置用户本地状态
 * @param {string} key
 * @param {string} value
 * @param {object} option
 */
export const setSessionStore = (key, value) => {
  if (window.sessionStorage) {
    return window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};
export const getSessionStore = (key) => {
  if (window.sessionStorage) {
    const value = window.sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  return null;
};
export const removeSessionStore = (key) => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(key);
  }
};
