import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Register from "./register";
import AccountCompleted from './accountCompleted'
import FurtherInformation from "./furtherInformation";
import Audit from "./audit";
import Monitor from "./monitor";
import Page from "./page";
export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/completed">
          <AccountCompleted />
        </Route>
        <Route path="/further-information/:updateCustomerUuid">
          <FurtherInformation />
        </Route>
        <Route path="/audit">
          <Audit />
        </Route>
        <Route path="/monitor">
          <Monitor />
        </Route>
        <Route path="/page/:id">
          <Page />
        </Route>
        <Route path="/">
          <Register />
        </Route>
      </Switch>
    </Router>
  );
};
