import React from "react";
import styles from "./styles.module.scss";
export default () => {
  return (
    <footer className={`${styles.footerContainer}`}>
      <div className={`${styles.left}`}>
        <i></i>
        <p>
          美 国 NFA 监 管：<span>0525929</span>
        </p>
        <p>
          加拿大MSB监管：<span>M20540481</span>
        </p>
        <p>
          © Copyright 2018-{new Date().getFullYear()} 必拓环球（BT
          Global）版权所有 不得转载
        </p>
      </div>
      <div className={`${styles.right}`}>
        <div className={`${styles.item_1}`}>
          <i></i>
          <p>双重权威监管</p>
        </div>
        <div className={`${styles.item_2}`}>
          <i></i>
          <p>超低交易门槛</p>
        </div>
        <div className={`${styles.item_3}`}>
          <i></i>
          <p>40+热门产品</p>
        </div>
        <div className={`${styles.item_4}`}>
          <i></i>
          <p>订单极速成交</p>
        </div>
      </div>
    </footer>
  );
};
