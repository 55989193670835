import React, { useCallback } from "react";
import { linkConfig } from "../../config";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
export default ({ submit = () => {} }) => {
  const location = useLocation();
  const leveHander = useCallback(() => {
    submit();
  }, [submit]);
  return (
    <header className={styles.navigation}>
      <div className="container flex-container flex-space-between flex-y-center">
        <a href={linkConfig.web} className="logo" target="_blank"></a>
        <svg
          onClick={leveHander}
          className={styles.back}
          t="1592480523347"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1141"
          width="32"
          height="32"
        >
          <defs>
            <style type="text/css"></style>
          </defs>
          <path
            d="M942.545455 478.021818H165.748364l361.239272-361.192727a34.909091 34.909091 0 1 0-49.384727-49.384727L56.785455 488.261818a33.838545 33.838545 0 0 0-7.493819 11.264 34.536727 34.536727 0 0 0 7.493819 38.120727l420.817454 420.817455a34.816 34.816 0 0 0 49.338182 0 34.909091 34.909091 0 0 0 0-49.384727l-361.239273-361.192728h776.843637a34.909091 34.909091 0 0 0 0-69.864727z"
            p-id="1142"
          ></path>
        </svg>
        {!location.search?.includes("from=app") && (
          <div>
            <span className="download">
              APP下载
              <span className="qrcode">Android & IOS用户 扫码下载</span>
            </span>
            <a href={linkConfig.online}>在线客服</a>
          </div>
        )}
      </div>
    </header>
  );
};
