import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik } from "formik";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { parse } from "qs";
import { updateAccount, getAuthUrl } from "../services";
import Utils from "../utils";
import Layout from "../components/Layout";
import GetProcess from "../components/GetProcess";
import styles from "./styles.module.scss";

const { downloadNow } = Utils;


export default () => {
  const formRef = useRef(null);
  const history = useHistory();
  // 追踪参数
  const { updateCustomerUuid } = useParams();
  //状态
  const [isSubmiting, setSubmiting] = useState(false);
  const [state, setState] = useState({
    url: "",
    visible: false,
  });

  const location = useLocation();
  const params = parse(location.search.slice(1));

  const isApp = () => {
    if (params.from instanceof Array) {
      return params.from.includes('app');
    } else {
      return params.from === 'app'
    }
  }

  const clientWidth = document.body.clientWidth || 1080;
  const isPc = clientWidth > 1080;
  
  const isApp_key = isApp() || isPc;
  
  const [tips, setTips] = useState({
    type: null,
    msg: null,
  });

  const setMessage = useCallback(({ type, msg, time = 5 }) => {
    setTips({
      type,
      msg,
    });
    const timer = setTimeout(() => {
      setTips({ type: null, msg: null });
      clearTimeout(timer);
    }, time * 1000);
  }, []);

  const updateCustomer = useCallback(
    async (values) => {
      try {
        setSubmiting(true);
        const res = await updateAccount({
          ...values,
          updateCustomerUuid,
        });
        setSubmiting(false);
        if (res.code !== "OK") {
          setMessage({
            type: "error",
            msg: res.msg,
          });

          return;
        }
        if (res.code === "OK" && res.data) {
          if (!res.data.isInAudit) {
            // 正在审核中
            history.replace("/audit");
            return;
          } else {
            // 审核完成
            history.replace("/completed");
            return;
          }
        }
        if (window.sessionStorage) {
          const sessionStorage = window.sessionStorage;
          const phone = sessionStorage.getItem("__r__");
          const pwd = sessionStorage.getItem("__p__");
          const signature = phone + "#" + pwd + "#3#0";
          if (window.isNative) {
            window.isNative.willLoginWith &&
              window.isNative.willLoginWith(signature);
            return;
          }
          if (window.webkit && window.webkit.messageHandlers.willLoginWith) {
            window.webkit.messageHandlers.willLoginWith.postMessage({
              body: signature,
            });
            return;
          }
          if (window.willLoginWith) {
            window.willLoginWith(signature);
            return;
          }
          // 监听安卓物理返回键
          if (window.isNative && window.isNative.getUserAccount) {
            window.isNative.getUserAccount(signature);
            return;
          }
        }

        if (state.url) {
          setState((t) => ({
            ...t,
            visible: true,
          }));
          return;
        }
        const authUrl = await getAuthUrl(updateCustomerUuid);
        if (authUrl.code !== "OK") {
          setMessage({
            type: "error",
            msg: authUrl.msg,
          });

          return;
        }

        setState((t) => ({
          ...t,
          url: authUrl.data,
          visible: true,
        }));
      } catch (error) {}
    },
    [updateCustomerUuid, state.url]
  );

  useEffect(() => {
    (async () => {
      const authUrl = await getAuthUrl(updateCustomerUuid);
      if (authUrl.code === "OK") {
        const url = authUrl.data
        setState((t) => ({
          ...t,
          url,
        }));
      }
    })();
  }, [updateCustomerUuid]);

  const handleBtn =() => {
    if (isApp_key) {
      window.location.href = state.url
    } else {
      downloadNow()
    }
  }

  // 领取流程參數
  const processArr = [
    {
      number: 1,
      type: "success"
    },
    {
      number: 2,
      type: "active"
    },
    {
      number: 3,
      type: "null"
    },
    {
      number: 4,
      type: "null"
    },
  ]

  return (
    <Layout
      className="flex-container layout-vertical"
      title="完善资料可领取50元红包，确定放弃吗?"
      again_title="开户成功"
      cancel="确认跳过"
      redirect={state.url.replace('https://fo.atbgovernor.com/Frontoffice/fundDepositOnline.do', 'https://fo.atbgovernor.com/Frontoffice/index.do')}
      skip={false}
      step={2}
      showBanner={true}
      showDownload={true}
      bannerType={2}
    >
      <div className={`${styles.wrapper} wrapBg`}>
        <div className={`${styles.flex_container} form flex-block`}>
          <Formik
            innerRef={formRef}
            initialValues={{
              chineseName: "",
              idDocumentNumber: "",
              email: "",
            }}
            validationSchema={yup.object().shape({
              chineseName: yup
                .string()
                .required("请输入姓名")
                .matches(Utils.validate.chineseName, "请输入正确姓名"),
              idDocumentNumber: yup
                .string()
                .required("请输入身份证号码")
                .matches(
                  Utils.validate.idDocumentNumber,
                  "请输入正确的身份证号码"
                ),
              email: yup
                .string()
                .required("请输入电子邮箱")
                .matches(Utils.validate.email, "请输入正确的电子邮箱"),
            })}
            onSubmit={(values) => {
              updateCustomer(values);
            }}
          >
            {({
              handleSubmit,
              touched,
              values,
              errors,
              setFieldValue,
              isValid,
              dirty,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <h3>完善资料</h3> 
                <div
                  className={`${
                    (values.chineseName || touched.chineseName) &&
                    errors.chineseName
                      ? "err"
                      : ""
                  } item`}
                >
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入姓名"
                      maxLength={11}
                      onChange={(e) => {
                        setFieldValue("chineseName", e.target.value);
                      }}
                    />
                  </div>
                  {(values.chineseName || touched.chineseName) &&
                    errors.chineseName && (
                      <div className="error">{errors.chineseName}</div>
                    )}
                </div>
                <div
                  className={`${
                    (values.idDocumentNumber || touched.idDocumentNumber) &&
                    errors.idDocumentNumber
                      ? "err"
                      : ""
                  } item`}
                >
                  <div className={`form-item`}>
                    <input
                      type="text"
                      placeholder="请输入身份证号码"
                      maxLength={18}
                      onChange={(e) =>
                        setFieldValue("idDocumentNumber", e.target.value)
                      }
                    />
                  </div>
                  {(values.idDocumentNumber || touched.idDocumentNumber) &&
                    errors.idDocumentNumber && (
                      <div className="error">{errors.idDocumentNumber}</div>
                    )}
                </div>

                <div
                  className={`${
                    (values.email || touched.email) && errors.email ? "err" : ""
                  } item`}
                >
                  <div className={`form-item email`}>
                    <input
                      type="text"
                      placeholder="请输入电子邮箱"
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                  </div>
                  {(values.email || touched.email) && errors.email && (
                    <div className="error">{errors.email}</div>
                  )}
                </div>
                <div className={styles.tips_message}>
                  {tips.msg && <p className={`${tips.type}`}>{tips.msg}</p>}
                </div>
                <div className="tools">
                  <button
                    type="submit"
                    disabled={isSubmiting}
                    className="block-show btn btn-register"
                  >
                    {isSubmiting ? "提交中..." : "领取50元"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <GetProcess processArr={processArr}/>
      {/* {state.visible && (
        <div className={styles.popShow}>
          <div className={styles.success}>
            <h2>资料完善成功</h2>
            <p>
              <b>50元</b>红包已发放至您账户
            </p>
            <div className={styles.adv_word}>{isApp_key ? '激活最高可领取赠金' : '下载App领取'}</div>
            <div className={styles.btn_box}>
              <a className="auth" onClick={handleBtn}>
                {isApp_key ? '立即激活' : '立即下载'}
              </a>
              <a href={state.url}>{isApp_key ? '暂不激活' : '暂不下载'}</a>
            </div>
          </div>
        </div>
      )} */}
    </Layout>
  );
};
